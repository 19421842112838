import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Generations of students from around the world dedicated to map-making have
passed through this  building, the last of them leaving in 2000. Now the
original building of ITC in Delft is no more. It  was demolished in October 2010.
ITC itself, now a faculty of TU Twente, will celebrate its 60th
anniversary in Enschede in December 2010. `}</p>
    <p>{`In Delft, the neighbouring Mijnbouw building is already open in its new role as
TU Delft's Technical  Museum. The remainder of the surrounding area is scheduled
for re-development with extensive  accommodation facilities for hundreds of
overseas students. `}</p>
    <PublishDate noday mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      